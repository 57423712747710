import * as uuidv4 from 'uuid/v4';

export class Project {
  id?: string;
  searchResult?: any;
  name?: string;
  tel?: string;
  address?: string;
  projectArea?: {
    type: 'Feature';
    geometry: GeoJSON.Polygon;
    properties: {
      bearing: number,
      zoom: number
    }
  };
  mapImage?: any;
  mapWidth?: number;
  mapHeight?: number;

  constructor(properties?) {
    Object.assign(this, properties);
    this.id = this.id || uuidv4();
  }

  public hasArea() {
    return this.projectArea && this.projectArea.geometry !== null && this.projectArea.geometry !== undefined;
  }

  public hasSearchResult() {
    return this.searchResult !== null && this.searchResult !== undefined;
  }
}
