import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from 'src/app/shared/project.service';

@Injectable({
  providedIn: 'root'
})
export class Step3Guard implements CanActivate {
  private active;
  constructor(private projectService: ProjectService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.active = false;

    if (this.projectService.project.hasArea()) {
      this.active = true;
    }
    return this.active;
  }
}
