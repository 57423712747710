import { Injectable } from '@angular/core';
import { Project } from './project.model';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private _project = new BehaviorSubject(new Project());
  public createProjectAreaTrigger = new Subject();

  constructor(
  ) {

  }

  public getProjectSubject() {
    return this._project;
  }

  public set project(project: Project) {
    this._project.next(new Project(project));
  }

  public get project() {
    return this._project.getValue();
  }

  public addSearchResult(place) {
    const newProject = this.project;
    newProject.searchResult = place;
    this.project = newProject;
    this.addProjectNameTelAddress(undefined, place.weergavenaam, undefined);
  }

  public addProjectArea(projectArea) {
    const newProject = this.project;
    newProject.projectArea = projectArea;
    this.project = newProject;
  }

  public removeProjectArea() {
    const newProject = this.project;
    newProject.projectArea = null;
    this.project = newProject;
  }

  public addProjectNameTelAddress(name: string, address: string, tel: string) {
    const newProject = this.project;
    newProject.name = name;
    newProject.address = address;
    newProject.tel = tel;
    this.project = newProject;
  }

  public addMapImage(mapImage, mapWidth, mapHeight) {
    const newProject = this.project;
    newProject.mapImage	 = mapImage;
    newProject.mapWidth = mapWidth;
    newProject.mapHeight = mapHeight;
    this.project = newProject;
  }

  public removeMapImage() {
    const newProject = this.project;
    newProject.mapImage = null;
    this.project = newProject;
  }

  public triggerProjectAreaCreate() {
    this.createProjectAreaTrigger.next(null);
  }

  public resetProject() {
    const newProject = new Project();
    this.project = newProject;
  }



}
