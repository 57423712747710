import { Component, OnInit } from '@angular/core';
import { ProgressService } from './progress.service';

import { fadeIn } from '../shared/animations';

import { RestartDialogComponent } from '../shared/dialogs/restart-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  animations: [
    fadeIn
  ]
})

export class WorkflowComponent implements OnInit {

  constructor(
    private progressService: ProgressService,
    private dialog: MatDialog,
    public router: Router
    ) { }

  ngOnInit() {
  }

  public getSteps() {
    return this.progressService.stepsInOrder;
  }

  public getPreviousStep() {
    return this.progressService.previous();
  }

  public getNextStep() {
    return this.progressService.next();
  }

  public disableNextStep() {
    return !this.progressService.allowedToActivateNextStep(this.getNextStep());
  }

  public isCompleted(step) {
    const stepIndex = this.progressService.findStepIndex(step.absoluteUrl);
    return stepIndex < this.progressService.currentIndex;
  }

  public openTutorial(step) {
    event.stopPropagation();
  }

  public initiateRestart() {
    this.dialog.open(RestartDialogComponent).afterClosed().subscribe(shouldRestart => {
      if (shouldRestart) {
        this.router.navigate(['workflow', 'stap1'], { queryParams: {restart: 'yes'}});
        // code for doing the restart!
      }
    });
  }

}
