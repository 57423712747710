import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-explanation-dialog',
  templateUrl: './explanation-dialog.component.html',
  styleUrls: ['./explanation-dialog.component.scss']
})
export class ExplanationDialogComponent implements OnInit {
  public header: string;
  public text: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.header = data.header;
    this.text = data.text;
  }

  ngOnInit() {
  }

}
