import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from 'src/app/shared/project.service';

@Injectable({
  providedIn: 'root'
})
export class Step2Guard implements CanActivate {
  constructor(private projectService: ProjectService) {

  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let activate = false;
    if (this.projectService.project.hasSearchResult()) {
      activate = true;
    }
    return activate;
  }
}
