import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class GeneralErrorHandler implements ErrorHandler {
  constructor(private snackbar: MatSnackBar) {

  }
  handleError(error: Error) {
    console.error(error);
    // do something with the exception
    this.snackbar.dismiss();
    const snackBarRef = this.snackbar.open('Oeps, er is iets misgegaan. De pagina wordt nu herladen.', 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'styled-snackbar'
    });
    snackBarRef.onAction().subscribe(() => {
      window.location.reload();
    });
    setTimeout(() => {
      // window.location.reload();
    }, 2000);
    throw error;
  }
}

