import { FarmObjectTypes } from './farm-object-types.enum';
import * as uuidv4 from 'uuid/v4';
// import { GeoJSON } from 'geojson';

export class FarmFeature implements GeoJSON.Feature {
    id: string;
    type: 'Feature' = 'Feature';
    geometry: GeoJSON.Point | GeoJSON.Polygon;
    properties: {
        objectType: FarmObjectTypes
    };

    constructor(type: FarmObjectTypes, geometry?: GeoJSON.Point | GeoJSON.Polygon, id?: string) {
        this.id = id || uuidv4();
        this.properties = {
            objectType: type
        };
        this.geometry = geometry || null;
    }
}
