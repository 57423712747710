import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRouterModule } from './app-router/app-router.module';
import { WorkflowComponent } from './workflow/workflow.component';
import { WelcomeComponent } from './workflow/welcome.component';
import { SharedModule } from './shared/shared.module';
import { GeneralErrorHandler } from './general-error.handler';



@NgModule({
  declarations: [
    AppComponent,
    WorkflowComponent,
    WelcomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    SharedModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GeneralErrorHandler,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
