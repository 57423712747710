import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Step2Guard } from './step2/step2.guard';
import { Step3Guard } from './step3/step3.guard';
import { Step4Guard } from './step4/step4.guard';
import { DataStoreService } from '../shared/data-store.service';
import { DataStoreKeys } from '../shared/data-store-keys.enum';
import { ProjectService } from '../shared/project.service';
import { filter, map } from 'rxjs/operators';
import { FarmFeatureService } from '../shared/farm-feature.service';

@Injectable({
  providedIn: 'root'
})

export class ProgressService {
  // These have to match the routes specified in workflow-routing.module.
  // They also have to have the guards assigned to them in the modules of the individual steps.
  // NOTE: Perhaps we should find a less error prone method of filling these steps.
  public stepsInOrder: Step[] = [
    {
      relativeUrl: 'stap1',
      label: 'Zoeken',
      number: '1',
      absoluteUrl: '/workflow/stap1',
      canActivate: []
    },
    {
      relativeUrl: 'stap2',
      label: 'Gebied selecteren',
      number: '2',
      absoluteUrl: '/workflow/stap2',
      canActivate: [new Step2Guard(this.projectService)]
    },
    {
      relativeUrl: 'stap3',
      label: 'Plattegrond maken',
      number: '3',
      absoluteUrl: '/workflow/stap3',
      canActivate: [new Step3Guard(this.projectService)]
    },
    {
      relativeUrl: 'stap4',
      label: 'Opslaan & afdrukken',
      number: '4',
      absoluteUrl: '/workflow/stap4',
      canActivate: [new Step4Guard(this.projectService, this.farmFeatureService)]
    }
  ];

  public currentIndex = 0;
  public maxVisitedIndex = 0;
  public lastStepVisited = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataStoreService: DataStoreService,
    private projectService: ProjectService,
    private farmFeatureService: FarmFeatureService
  ) {
    this.loadProgress();
    this.subscribeToRouteChange();
  }

  private subscribeToRouteChange() {
    // Track at which step the user is.
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.urlAfterRedirects),
      filter(this.isWorkflowUrl)
    ).subscribe((url: string) => {
      // Only update when the URL is actually a workflow URL.
      const stepIndex = this.findStepIndex(url);
      this.updateProgress(stepIndex);
    });
  }

  private isWorkflowUrl(url) {
    return url.includes('workflow');
  }

  public updateProgress(stepIndex: number) {
    this.currentIndex = stepIndex;
    if (this.maxVisitedIndex < stepIndex) {
      this.maxVisitedIndex = stepIndex;
    }
    this.saveProgress();
  }

  public findStepIndex(absoluteUrl: string): number {

    const urlWithoutParams = absoluteUrl.split('?')[0];

    const index = this.stepsInOrder.findIndex(step => step.absoluteUrl === urlWithoutParams);
    return index;
  }

  public loadProgress() {
    const maxIndex = this.dataStoreService.getFromLocalStorage(DataStoreKeys.MaxIndex);
    const curIndex = this.dataStoreService.getFromLocalStorage(DataStoreKeys.CurrentIndex);
    if (maxIndex !== null) {
      this.maxVisitedIndex = parseInt(maxIndex, 10);
    }

    if (curIndex !== null) {
      this.currentIndex = parseInt(curIndex, 10);
    }

  }
  public saveProgress() {
    this.dataStoreService.writeToLocalStorage(DataStoreKeys.CurrentIndex, this.currentIndex.toString());
    this.dataStoreService.writeToLocalStorage(DataStoreKeys.MaxIndex, this.maxVisitedIndex.toString());
    this.dataStoreService.writeToLocalStorage(DataStoreKeys.LastStepVisited, this.lastStepVisited.toString());
  }

  public resetProgress() {
    this.maxVisitedIndex = 0;
    this.currentIndex = 0;
    this.dataStoreService.writeToLocalStorage(DataStoreKeys.CurrentIndex, null);
    this.dataStoreService.writeToLocalStorage(DataStoreKeys.MaxIndex, null);
  }

  public next(): Step | null {
    let nextStep = null;
    if (this.currentIndex < this.stepsInOrder.length - 1) {
      nextStep = this.stepsInOrder[this.currentIndex + 1];
    }

    return nextStep;
  }

  public previous(): Step | null {
    let previousStep = null;
    if (this.currentIndex > 0) {
      previousStep = this.stepsInOrder[this.currentIndex - 1];
    }
    return previousStep;
  }

  public allowedToActivateStep(step: Step): boolean {
    // Use this function to determine if the user is allowed to go to the next step.
    const stepIndex = this.findStepIndex(step.absoluteUrl);
    const isNotAllowed = this.stepsInOrder[stepIndex].canActivate.map(guard => {
      const canActivate = guard.canActivate(this.route.snapshot.url, this.router.routerState.snapshot);
      return canActivate;
    }).includes(false);
    const isAllowed = !isNotAllowed;
    return isAllowed;
  }


  public allowedToActivateNextStep(nextStep: Step): boolean {
    // Use this function to determine if the user is allowed to go to the next step.
    const stepIndex = this.findStepIndex(nextStep.absoluteUrl);
    const isNotAllowed = this.stepsInOrder[stepIndex].canActivate.map(guard => {
      const canActivate = guard.canActivate(this.route.snapshot.url, this.router.routerState.snapshot);
      return canActivate;
    }).includes(false);
    const isAllowed = !isNotAllowed;
    return isAllowed;
  }

  public forceRestart() {
    this.projectService.resetProject();
    this.farmFeatureService.reset();
    this.resetProgress();
  }
}


export class Step {
  relativeUrl: string;
  absoluteUrl: string;
  number: string;
  label: string;
  canActivate: any[];
}
