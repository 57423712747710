import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import * as mapboxgl from 'mapbox-gl';
import { Config } from './app/config/config';


// Another key needs to be used in production mode
if (!environment.production) {
  (mapboxgl as any).accessToken = Config.mapbox.token;
}

if (environment.production) {
  enableProdMode();
}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

