import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './page-not-found.component';
import { WelcomeComponent } from '../workflow/welcome.component';
import { WorkflowComponent } from '../workflow/workflow.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welkom'
  },
  {
    path: 'welkom',
    component: WelcomeComponent
  },
  {
    path: 'workflow',
    component: WorkflowComponent,
    loadChildren: '../workflow/workflow.module#WorkflowModule',
  },
  {
    path: 'menu',
    loadChildren: '../menu/menu.module#MenuModule'
  },
  {
    path: 'pagina-niet-gevonden',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'pagina-niet-gevonden'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    SharedModule,
  ],
  exports: [
    RouterModule,
  ],
  declarations: [PageNotFoundComponent],
  providers: []
})
export class AppRouterModule { }
