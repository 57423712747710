import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchmeaMaterialModule } from './achmea-material.module';
import { RestartDialogComponent } from './dialogs/restart-dialog.component';
import { ExplanationDialogComponent } from './dialogs/explanation-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AchmeaMaterialModule
  ],
  declarations: [
    RestartDialogComponent,
    ExplanationDialogComponent
  ],
  entryComponents: [
    RestartDialogComponent,
    ExplanationDialogComponent
  ],
  exports: [
    AchmeaMaterialModule
  ]
})
export class SharedModule { }
