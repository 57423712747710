import { Injectable } from '@angular/core';
import { FarmFeature } from './farm-feature.model';
import { FarmObjectTypes } from './farm-object-types.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FarmFeatureService {
  public features: BehaviorSubject<FarmFeature[]> = new BehaviorSubject([]);
  public activeFeatureType$: BehaviorSubject<FarmObjectTypes> = new BehaviorSubject(null);
  constructor() {
    this.features.subscribe(e => {
    });
  }

  public setFeatures(features: FarmFeature[]): void {
    this.features.next(features);
  }

  public setActiveFeatureType(type: FarmObjectTypes): void {
    this.activeFeatureType$.next(type);
  }

  public getActiveFeatureType(): FarmObjectTypes {
    return this.activeFeatureType$.getValue();
  }

  public resetActiveFeatureType() {
    this.activeFeatureType$.next(null);
  }

  public create(geometry: GeoJSON.Point | GeoJSON.Polygon, id?: string): FarmFeature {
    const feature = new FarmFeature(this.activeFeatureType$.getValue(), geometry, id);
    this.resetActiveFeatureType();
    const featuresSnapshot = this.features.value;
    featuresSnapshot.push(feature);
    this.setFeatures(featuresSnapshot);
    return feature;
  }

  public get(id: string): FarmFeature {
    return this.features.value.find(f => f.id === id);
  }

  public delete(id: string): void {
    // Replaces original features array.
    const featuresSnapshot = this.features.getValue();
    const filteredFeatures = featuresSnapshot.filter(f => f.id !== id);
    this.setFeatures(filteredFeatures);
  }

  public update(feature: FarmFeature): FarmFeature {
    const featuresSnapshot = this.features.value;
    const oldFeatureIndex = featuresSnapshot.findIndex(f => f.id === feature.id);
    featuresSnapshot[oldFeatureIndex] = feature as FarmFeature;
    this.setFeatures(featuresSnapshot);
    return this.features.getValue()[oldFeatureIndex];
  }

  public getByType(type: FarmObjectTypes) {
    return this.features.value.filter(f => f.properties.objectType === type);
  }

  public destroyAllType(type: FarmObjectTypes) {
    const featureSnapshot = this.features.getValue();
    const filteredFeatures = featureSnapshot.filter(f => f.properties.objectType !== type);
    this.setFeatures(filteredFeatures);

  }

  public hasAFeature(): boolean {
    if (this.features.getValue().length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public reset(): void {
    this.setFeatures([]);
  }

}
