import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule,
         MatCheckboxModule,
         MatSliderModule,
         MatFormFieldModule,
         MatInputModule,
         MatSnackBarModule,
         MatDialogModule,
         MatDividerModule} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';


const materialModules = [
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatCheckboxModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSliderModule,
  MatSnackBarModule,
  MatDividerModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ...materialModules
  ],
  declarations: [],
  exports: [
    ...materialModules,
    FormsModule
  ]
})
export class AchmeaMaterialModule { }
