import { Injectable, OnInit } from '@angular/core';
import { FarmFeatureService } from './farm-feature.service';
import { DataStoreKeys } from './data-store-keys.enum';
import { ProjectService } from './project.service';


@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  constructor (
    private farmFeatureService: FarmFeatureService,
    private projectService: ProjectService
  ) {
    this.setFarmFeaturesOnInit();
    this.setProjectOnInit();
    this.subscribeToFarmFeatures();
    this.subscribeToProject();
  }

  private setFarmFeaturesOnInit() {
    const previouslyStoredFarmFeatures = this.getFromLocalStorage(DataStoreKeys.FarmFeatures);
    if (previouslyStoredFarmFeatures !== null) {
      this.farmFeatureService.setFeatures(previouslyStoredFarmFeatures);
    }
  }

  private subscribeToFarmFeatures() {
    this.farmFeatureService.features.subscribe((features) => {
      if (features) {
        this.writeToLocalStorage(DataStoreKeys.FarmFeatures, features);
      }
    });
  }

  private subscribeToProject() {
    this.projectService.getProjectSubject().subscribe(project => {
      this.writeToLocalStorage(DataStoreKeys.Project, project);
    });
  }

  private setProjectOnInit() {
    const project = this.getFromLocalStorage(DataStoreKeys.Project);
    this.projectService.project = project;
  }

  public writeToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getFromLocalStorage(key) {
    const value = JSON.parse(localStorage.getItem(key));
    return value;
  }
}
